import { Space, Image, message, Modal } from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../../components/AppButton';
import AppCard from '../../../components/AppCard';
import HeaderSection from '../../../components/HeaderSection';
import { httpRequest } from '../../../helpers/api';
import { BaseResponseProps } from '../../../types/config.type';
import SectionContent from '../../../components/SectionContent';
import DetailItem from '../../../components/DetailItem';
import useDetailBreadcrumbs from '../../../hooks/useDetailBreadcrumbs';
import { ArticleProps, initialArticle } from '../../../types/article.type';
import { TopicProps } from '../../../types/topic.type';
import HTMLPreview from '../../../components/HTMLPreview';
import moment from 'moment';
import { getErrorMessage } from '@qlibs/utils';
import { toTitleCase } from '../../../helpers/text';

interface IParams {
	articleId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<ArticleProps, 'createdAt' | 'updatedAt'>;
}

const ArticleDetail: React.FC = () => {
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();
	const history = useHistory();
	const { articleId } = useParams<IParams>();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [article, setArticle] = React.useState<ArticleProps>(initialArticle);
	const [isModalRepublishVisible, setIsModalRepublishVisible] =
		React.useState<boolean>(false);
	const [isLoadingRepublish, setIsLoadingRepublish] =
		React.useState<boolean>(false);

	const handleRepublish = async () => {
		try {
			setIsLoadingRepublish(true);
			const res = await httpRequest.patch(`/articles/${articleId}/republish`);
			if (res) {
				message.success('Success republish article');
			}
		} catch (err) {
			const error = getErrorMessage(err);
			message.error('Failed republish article' + error);
		} finally {
			setIsLoadingRepublish(false);
			setIsModalRepublishVisible(false);
		}
	};

	React.useEffect(() => {
		const fetchArticleDetail = async () => {
			try {
				setIsLoading(true);

				const res = await httpRequest.get<ResponseProps>(
					'/articles/' + articleId,
				);
				setArticle(res.data.payload);

				setIsLoading(false);

				const bcDetails = [
					{
						field: 'articleId',
						value: articleId,
						label: res.data.payload.title,
					},
				];
				setBreadcrumbDetails(bcDetails);
			} catch (error) {
				setIsLoading(false);
			}
		};

		fetchArticleDetail();
	}, [articleId]);

	return (
		<div>
			<HeaderSection
				icon="back"
				title="Detail Article"
				subtitle="Manage your article data"
				rightAction={
					<Space>
						<AppButton
							type="default"
							onClick={() => setIsModalRepublishVisible(true)}
						>
							Republish
						</AppButton>
						<AppButton
							type="primary"
							onClick={() => history.push('/article/' + articleId + '/edit')}
						>
							Edit
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<SectionContent
					groupTitle="Article Information"
					helpers={[
						{
							title: 'Information',
							content: (
								<div
									style={{
										maxWidth: '80%',
										wordWrap: 'break-word',
										overflow: 'hidden',
										whiteSpace: 'normal',
									}}
								>
									Accepted image type are jpg, jpeg and png. Max file size is 1
									Mb.
								</div>
							),
						},
					]}
				>
					<Space
						style={{
							width: '100%',
							background: 'grey',
							justifyContent: 'center',
							marginBottom: '20px',
						}}
					>
						<Image
							preview={false}
							width={600}
							height={400}
							src={
								article.images ? article.images[0] : '/images/select-image.jpg'
							}
							fallback={'/images/blur-image.jpeg'}
							style={{ objectFit: 'cover' }}
							placeholder={
								<Image
									preview={false}
									src="/images/blur-image.jpeg"
									width="100%"
									height={200}
									style={{ objectFit: 'cover' }}
								/>
							}
						/>
					</Space>

					<DetailItem label="Headline" content={article.title} />

					<DetailItem
						label="Article Content"
						content={<HTMLPreview html={article.contentText} />}
					/>

					<DetailItem
						label="Reference"
						content={
							<a
								style={{ color: 'blue', textDecoration: 'underline' }}
								href={article?.rssDetail?.fullArticleUrl}
								target="_blank"
								rel="noreferrer"
							>
								{`${article?.title} by ${
									article?.rssDetail?.rssUrl
										? article?.rssDetail?.rssUrl
										: article?.metaCreatedByUser?.name
								} Published at ${moment(article?.publishedAt).format(
									'MMMM Do YYYY - hh:mm',
								)}`}
							</a>
						}
					/>

					<DetailItem
						label="Article Tag"
						content={sortTopics(article.topics || [])
							.map((item) => item.topicName)
							.join(', ')}
					/>

					<DetailItem
						label="Health Tracker Related"
						content={
							article.phtTopic ? toTitleCase(article.phtTopic) : 'Not related'
						}
					/>

					<DetailItem
						label="Publish For"
						content={
							article.isPublished
								? article.targetReaders.length < 2
									? article.targetReaders.map((element) => {
											return <h4>{element}</h4>;
									  })
									: 'ALL'
								: 'Unpublished'
						}
					/>
				</SectionContent>
			</AppCard>
			<Modal
				title="Confirmation"
				visible={isModalRepublishVisible}
				onOk={handleRepublish}
				onCancel={() => {
					setIsModalRepublishVisible(false);
				}}
				footer={false}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						textAlign: 'center',
						marginInline: 10,
						marginBottom: 10,
					}}
				>
					<h2>You will republish this article.</h2>
					<p>
						The content will remain the same only the publish date will be
						updated to now
					</p>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						gap: 10,
					}}
				>
					<AppButton
						type="default"
						onClick={() => setIsModalRepublishVisible(false)}
						loading={isLoadingRepublish}
						style={{ flex: 1, height: 40 }}
					>
						Cancel
					</AppButton>
					<AppButton
						type="primary"
						onClick={handleRepublish}
						loading={isLoadingRepublish}
						style={{ flex: 1, height: 40 }}
					>
						Yes, Republish
					</AppButton>
				</div>
			</Modal>
		</div>
	);
};

export default ArticleDetail;

function sortTopics(topics: TopicProps[]) {
	topics.sort((a, b) => a.topicName.localeCompare(b.topicName));
	return topics;
}
