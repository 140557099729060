import {
	AppstoreAddOutlined,
	MoreOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import {
	Dropdown,
	Image,
	Input,
	Menu,
	message,
	Modal,
	Pagination,
	Select,
	Space,
	Switch,
	Table,
	Tabs,
	Tag,
} from 'antd';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import AppButton from '../../../components/AppButton';
import HeaderSection from '../../../components/HeaderSection';
import { httpRequest } from '../../../helpers/api';
import useFetchList from '../../../hooks/useFetchList';
import { ArticleProps, initialArticle } from '../../../types/article.type';
import { BaseResponseProps } from '../../../types/config.type';
import { TopicProps } from '../../../types/topic.type';
import { getErrorMessage } from '@qlibs/utils';

interface ResponseProps extends BaseResponseProps {
	payload: Omit<ArticleProps, 'createdAt' | 'updatedAt'>;
}

interface DeleteResponseProps extends BaseResponseProps {
	payload: {
		isSuccess: boolean;
	};
}

const Article = () => {
	const history = useHistory();

	const [isLoadingUpdateStatus, setIsLoadingUpdateStatus] =
		React.useState<boolean>(false);
	const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
	const [tmpData, setTmpData] = React.useState<ArticleProps>(initialArticle);

	const [isLoadingDelete, setIsLoadingDelete] = React.useState<boolean>(false);
	const [isModalDeleteVisible, setIsModalDeleteVisible] =
		React.useState<boolean>(false);
	const [isLoadingRepublish, setIsLoadingRepublish] =
		React.useState<boolean>(false);
	const [articleId, setArticleId] = React.useState<string>();

	const [topicLoop, setTopicLoop] = React.useState(2);

	const [activeKey, setActiveKey] = useState<string>('1');

	const { TabPane } = Tabs;

	const {
		DEFAULT_LIMIT,
		isLoading,
		data,
		pagination,
		setData,
		setSearch,
		setQuery,
		changePage,
	} = useFetchList<ArticleProps>({
		endpoint: 'articles',
		initialQuery: {
			sort: 'publishedAt:DESC',
		},
		limit: 10,
	});

	const handleChangeTab = async (key: string) => {
		setActiveKey(key);
	};

	const handleStatusChange = async () => {
		try {
			setIsLoadingUpdateStatus(true);
			let newData = [];
			newData = data.map((item) => {
				if (item.articleId === tmpData.articleId) {
					return {
						...item,
						statusLoading: true,
					};
				}
				return item;
			});
			setData(newData);

			const res = await httpRequest.patch<ResponseProps>(
				'/articles/' + tmpData.articleId,
				{
					articleId: tmpData.articleId,
					isPublished: !tmpData.isPublished,
				},
			);

			newData = data.map((item) => {
				if (item.articleId === res.data.payload.articleId) {
					return {
						...item,
						isPublished: res.data.payload.isPublished,
						statusLoading: false,
					};
				}
				return item;
			});
			setData(newData);

			message.success('Success change ' + tmpData.title + ' status.');

			setIsLoadingUpdateStatus(false);
			setIsModalVisible(false);
			setTmpData(initialArticle);
		} catch (error: any) {
			message.error(error.data.message);
			setIsModalVisible(false);
			setTmpData(initialArticle);
			setIsLoadingUpdateStatus(false);
		}
	};

	const handleCreateArticle = () => {
		history.push('/article/add');
	};

	const handleRepublish = async () => {
		try {
			setIsLoadingRepublish(true);
			const res = await httpRequest.patch(`/articles/${articleId}/republish`);
			if (res) {
				message.success('Success republish article');
			}
		} catch (err) {
			const error = getErrorMessage(err);
			message.error('Failed republish article' + error);
		} finally {
			setIsLoadingRepublish(false);
			setArticleId('');
		}
	};

	const { Option } = Select;

	const handleChangeStatus = (status: string) => {
		if (status !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, isPublished: status }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, isPublished: undefined }));
		}
	};

	const handleChangePublishFor = (publish: string) => {
		if (publish !== 'all') {
			setQuery((oldVal) => ({ ...oldVal, targetReaders: publish }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, targetReaders: undefined }));
		}
	};

	const handleFromRSSContent = (key: string) => {
		if (key === '2') {
			setQuery((oldVal) => ({ ...oldVal, isFromRSS: 'true' }));
		} else if (key === '3') {
			setQuery((oldVal) => ({ ...oldVal, isFromRSS: 'false' }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, isFromRSS: false }));
		}
	};

	const handleClickDetail = (e: ArticleProps) => {
		history.push(`/article/${e.articleId}/detail`);
	};

	const handleClickAction = (props: ArticleProps, key: string) => {
		if (key === 'detail') {
			history.push(`/article/${props.articleId}/detail`);
		} else if (key === 'edit') {
			history.push(`/article/${props.articleId}/edit`);
		} else if (key === 'delete') {
			setTmpData(props);
			setIsModalDeleteVisible(true);
		} else if (key === 'republish') {
			setArticleId(props.articleId);
		}
	};

	const handleDelete = async () => {
		try {
			setIsLoadingDelete(true);
			let newData = [];
			newData = data.map((item) => {
				if (item.articleId === tmpData.articleId) {
					return {
						...item,
						statusLoading: true,
					};
				}
				return item;
			});
			setData(newData);

			const res = await httpRequest.delete<DeleteResponseProps>(
				'/articles/' + tmpData.articleId,
			);

			// if (
			// 	res.data.payload.isSuccess &&
			// 	tmpData.images &&
			// 	tmpData.images.length > 0
			// ) {
			// 	let promises: Promise<AxiosResponse<unknown, any>>[] = [];
			// 	tmpData.images.forEach((img) => {
			// 		const prom = httpRequest.delete<DeleteResponseProps>(
			// 			`/articles/${tmpData.articleId}/${img}`,
			// 		);
			// 		promises.push(prom);
			// 	});
			// 	Promise.all(promises);
			// }

			newData = data.filter((item) => item.articleId !== tmpData.articleId);
			setData(newData);

			message.success('Successfully deleted ' + tmpData.title + ' .');

			setIsLoadingDelete(false);
			setIsModalDeleteVisible(false);
			setTmpData(initialArticle);
		} catch (error: any) {
			message.error(error.data.message);
		} finally {
			setIsModalDeleteVisible(false);
			setTmpData(initialArticle);
			setIsLoadingDelete(false);
		}
	};

	const columns = [
		{
			title: 'IMAGE',
			dataIndex: 'images',
			key: 'images',
			render: (images: ArticleProps['images']) => {
				return (
					<Image
						width={150}
						height={100}
						style={{ borderRadius: '7%' }}
						src={
							images && images.length > 0
								? images[0] || '/images/blur-image.jpeg'
								: '/images/blur-image.jpeg'
						}
						placeholder={
							<Image
								preview={false}
								src="/images/blur-image.jpeg"
								width={120}
								height={100}
							/>
						}
					/>
				);
			},
		},
		{
			title: 'HEADLINE',
			dataIndex: 'title',
			key: 'title',
			render: (text: string, record: ArticleProps) => {
				return (
					<div
						className="table-link"
						onClick={() => handleClickDetail(record)}
						style={{
							wordBreak: 'break-word',
							whiteSpace: 'normal',
						}}
					>
						{text}
					</div>
				);
			},
		},
		{
			title: 'TAG',
			key: 'topics',
			dataIndex: 'topics',
			render: (status: any, record: ArticleProps) => (
				<>
					{record.topics
						? sortTopics(record.topics).map((element, index) => {
								if (index < topicLoop) {
									return (
										<div>
											<Tag
												style={{
													border: '2px solid #D81F64',
													color: '#D81F64',
													marginBottom: '7%',
												}}
											>
												{element.topicName}
											</Tag>
											<br />
										</div>
									);
								} else {
									if (index === record.topics.length - 1)
										return (
											<div>
												<Tag
													style={{
														border: '2px solid #D81F64',
														color: '#D81F64',
														marginBottom: '7%',
													}}
												>
													+{record.topics.length - 2} More
												</Tag>
												<br />
											</div>
										);
								}
						  })
						: []}
				</>
			),
		},
		{
			title: 'PUBLISH FOR',
			key: 'targetReaders',
			dataIndex: 'targetReaders',
			render: (status: any, record: ArticleProps) => (
				<>
					{record.targetReaders ? (
						record.targetReaders.length < 2 ? (
							record.targetReaders.map((element) => {
								return <h4>{element}</h4>;
							})
						) : (
							<h4>ALL</h4>
						)
					) : (
						[]
					)}
				</>
			),
		},
		{
			title: 'STATUS',
			key: 'status',
			dataIndex: 'status',
			render: (status: any, record: ArticleProps) => (
				<>
					{
						<Switch
							loading={record.statusLoading}
							checked={record.isPublished}
							onChange={() => {
								setIsModalVisible(true);
								setTmpData(record);
							}}
						/>
					}
				</>
			),
		},
		{
			title: 'CREATED',
			dataIndex: 'publishedAt',
			key: 'publishedAt',
			render: (publishedAt: any) => (
				<div>{moment(publishedAt).format('MMMM DD, YYYY, HH:mm')}</div>
			),
		},
		{
			title: '',
			key: 'action',
			render: (_: any, record: ArticleProps) => (
				<Dropdown overlay={() => menu(record)} placement="bottomRight">
					<MoreOutlined style={{ cursor: 'pointer' }} />
				</Dropdown>
			),
		},
	];

	const menu = (record: ArticleProps) => (
		<Menu onClick={(e) => handleClickAction(record, e.key)}>
			<Menu.Item key="republish">Republish Article</Menu.Item>
			<Menu.Item key="edit">Edit Article</Menu.Item>
			<Menu.Item key="detail">Detail Article</Menu.Item>
			<Menu.Item key="delete">Delete Article</Menu.Item>
		</Menu>
	);

	return (
		<div>
			<HeaderSection
				icon={<AppstoreAddOutlined />}
				title="Article"
				subtitle="Manage your Articles"
				rightAction={
					<Space>
						<AppButton type="primary" onClick={handleCreateArticle}>
							Add Article
						</AppButton>
					</Space>
				}
			/>

			<ContainerFilter>
				<Input
					size="large"
					placeholder="Search article name"
					prefix={<SearchOutlined />}
					allowClear
					onChange={(e) => setSearch(e.target.value)}
				/>
				<Select
					size="large"
					allowClear
					style={{ width: 160 }}
					onChange={handleChangePublishFor}
					placeholder="Publish For"
				>
					<Option value="all">All</Option>
					<Option value="DOCTOR">Doctor</Option>
					<Option value="PATIENT">Patient</Option>
				</Select>
				<Select
					size="large"
					allowClear
					style={{ width: 160 }}
					onChange={handleChangeStatus}
					placeholder="Status"
				>
					<Option value="all">All</Option>
					<Option value="active">Published</Option>
					<Option value="inactive">Unpublished</Option>
				</Select>
			</ContainerFilter>

			<div style={{ backgroundColor: 'white', padding: '1%' }}>
				<Tabs
					defaultActiveKey={activeKey}
					activeKey={activeKey}
					onChange={(e) => {
						handleFromRSSContent(e);
						handleChangeTab(e);
					}}
				>
					<TabPane tab="All Article" key={'1'}>
						<Table
							loading={isLoading}
							columns={columns}
							dataSource={data}
							pagination={false}
						/>

						{data.length !== 0 ? (
							<>
								<CustomPagination
									current={pagination.page}
									total={pagination.totalData}
									defaultPageSize={pagination.perPage}
									pageSizeOptions={['25', '50', '100']}
									showSizeChanger={true}
									showTotal={(total, range) =>
										`${range[0]} - ${range[1]} of ${total}`
									}
									onChange={changePage}
									locale={{ items_per_page: '' }}
									responsive={true}
								/>
								<PaginationText>
									<h4>Per Page</h4>
								</PaginationText>
							</>
						) : null}

						<Modal
							title="Update status confirmation"
							visible={isModalVisible}
							onOk={handleStatusChange}
							onCancel={() => {
								setIsModalVisible(false);
								setTmpData(initialArticle);
							}}
							okText="Yes"
							confirmLoading={isLoadingUpdateStatus}
							okButtonProps={{ type: 'primary' }}
						>
							<p>
								Are you sure want to change <b>"{tmpData.title}"</b> status to{' '}
								<b>"{!tmpData.isPublished ? 'Active' : 'Inactive'}"</b>?.{' '}
								{!tmpData.isPublished}
							</p>
						</Modal>

						<Modal
							title="Delete article confirmation"
							visible={isModalDeleteVisible}
							onOk={handleDelete}
							onCancel={() => {
								setIsModalDeleteVisible(false);
								setTmpData(initialArticle);
							}}
							okText="Yes"
							confirmLoading={isLoadingDelete}
							okButtonProps={{ type: 'primary' }}
						>
							<p>
								Are you sure want to delete article <b>{tmpData.title}</b>?
							</p>
						</Modal>
					</TabPane>

					<TabPane tab="From RSS" key={'2'}>
						<Table
							loading={isLoading}
							columns={columns}
							dataSource={data}
							pagination={false}
						/>

						{data.length !== 0 ? (
							<>
								<CustomPagination
									current={pagination.page}
									total={pagination.totalData}
									defaultPageSize={pagination.perPage}
									pageSizeOptions={['25', '50', '100']}
									showSizeChanger={true}
									showTotal={(total, range) =>
										`${range[0]} - ${range[1]} of ${total}`
									}
									onChange={changePage}
									locale={{ items_per_page: '' }}
									responsive={true}
								/>
								<PaginationText>
									<h4>Per Page</h4>
								</PaginationText>
							</>
						) : null}

						<Modal
							title="Update status confirmation"
							visible={isModalVisible}
							onOk={handleStatusChange}
							onCancel={() => {
								setIsModalVisible(false);
								setTmpData(initialArticle);
							}}
							okText="Yes"
							confirmLoading={isLoadingUpdateStatus}
							okButtonProps={{ type: 'primary' }}
						>
							<p>
								Are you sure want to change <b>"{tmpData.title}"</b> status to{' '}
								<b>"{!tmpData.isPublished ? 'Active' : 'Inactive'}"</b>?.{' '}
								{!tmpData.isPublished}
							</p>
						</Modal>

						<Modal
							title="Delete article confirmation"
							visible={isModalDeleteVisible}
							onOk={handleDelete}
							onCancel={() => {
								setIsModalDeleteVisible(false);
								setTmpData(initialArticle);
							}}
							okText="Yes"
							confirmLoading={isLoadingDelete}
							okButtonProps={{ type: 'primary' }}
						>
							<p>
								Are you sure want to delete article <b>{tmpData.title}</b>?
							</p>
						</Modal>
					</TabPane>

					<TabPane tab="Input Manual" key={'3'}>
						<Table
							loading={isLoading}
							columns={columns}
							dataSource={data}
							pagination={false}
						/>

						{data.length !== 0 ? (
							<>
								<CustomPagination
									current={pagination.page}
									total={pagination.totalData}
									defaultPageSize={pagination.perPage}
									pageSizeOptions={['25', '50', '100']}
									showSizeChanger={true}
									showTotal={(total, range) =>
										`${range[0]} - ${range[1]} of ${total}`
									}
									onChange={changePage}
									locale={{ items_per_page: '' }}
									responsive={true}
								/>
								<PaginationText>
									<h4>Per Page</h4>
								</PaginationText>
							</>
						) : null}

						<Modal
							title="Update status confirmation"
							visible={isModalVisible}
							onOk={handleStatusChange}
							onCancel={() => {
								setIsModalVisible(false);
								setTmpData(initialArticle);
							}}
							okText="Yes"
							confirmLoading={isLoadingUpdateStatus}
							okButtonProps={{ type: 'primary' }}
						>
							<p>
								Are you sure want to change <b>"{tmpData.title}"</b> status to{' '}
								<b>"{!tmpData.isPublished ? 'Active' : 'Inactive'}"</b>?.{' '}
								{!tmpData.isPublished}
							</p>
						</Modal>

						<Modal
							title="Delete article confirmation"
							visible={isModalDeleteVisible}
							onOk={handleDelete}
							onCancel={() => {
								setIsModalDeleteVisible(false);
								setTmpData(initialArticle);
							}}
							okText="Yes"
							confirmLoading={isLoadingDelete}
							okButtonProps={{ type: 'primary' }}
						>
							<p>
								Are you sure want to delete article <b>{tmpData.title}</b>?
							</p>
						</Modal>
					</TabPane>
				</Tabs>
			</div>
			<Modal
				title="Confirmation"
				visible={!!articleId}
				onOk={handleRepublish}
				onCancel={() => {
					setArticleId('');
				}}
				footer={false}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						textAlign: 'center',
						marginInline: 10,
						marginBottom: 10,
					}}
				>
					<h2>You will republish this article.</h2>
					<p>
						The content will remain the same only the publish date will be
						updated to now
					</p>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						gap: 10,
					}}
				>
					<AppButton
						type="default"
						onClick={() => setArticleId('')}
						loading={isLoadingRepublish}
						style={{ flex: 1, height: 40 }}
					>
						Cancel
					</AppButton>
					<AppButton
						type="primary"
						onClick={handleRepublish}
						loading={isLoadingRepublish}
						style={{ flex: 1, height: 40 }}
					>
						Yes, Republish
					</AppButton>
				</div>
			</Modal>
		</div>
	);
};

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

export default Article;

function sortTopics(topics: TopicProps[]) {
	topics.sort((a, b) => a.topicName.localeCompare(b.topicName));
	return topics;
}

const CustomPagination = styled(Pagination)`
	background-color: white;
	margin-top: 0;
	padding: 10px;

	.ant-pagination {
		display: flex;
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #d81f64;
		border-right-width: 1px;
	}

	.ant-pagination-options {
		order: 1;
		margin-left: 5rem;
	}

	li.ant-pagination-total-text {
		color: #93a1b0;
		order: 2;
	}

	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon {
		order: 3;
	}

	.ant-pagination-next {
		order: 4;
	}

	.ant-pagination-item:hover {
		border-color: #d81f64;
	}

	.ant-pagination-item-active a {
		color: #d81f64;
		border-color: #d81f64;
	}

	.ant-pagination-item-active {
		border-color: #d81f64;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: 1px solid transparent;
	}
`;

const PaginationText = styled.div`
	h4 {
		display: inline;
		position: relative;
		bottom: 38px;
		left: 10px;
		color: #93a1b0;
	}

	@media only screen and (max-width: 550px) {
		h4 {
			visibility: hidden;
		}
	}
`;
