import { MoreOutlined, SearchOutlined } from '@ant-design/icons';
import {
	Col,
	DatePicker,
	Dropdown,
	Input,
	Menu,
	message,
	Modal,
	Pagination,
	Row,
	Select,
	Space,
	Spin,
	Switch,
	Table,
	Typography,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as HardDriveGreyIcon } from '../../assets/icons/hard_drive_grey.svg';
import AppButton from '../../components/AppButton';
import HeaderSection from '../../components/HeaderSection';
import { httpRequest } from '../../helpers/api';
import useFetchList from '../../hooks/useFetchList';
import { BaseResponseProps } from '../../types/config.type';
import {
	initialPharmacy,
	PartnerProps,
	PharmacyProps,
} from '../../types/partner.type';
import { useDebounce } from 'use-debounce';

const { Text } = Typography;

interface ResponseProps extends BaseResponseProps {
	payload: Omit<PartnerProps, 'createdAt' | 'updatedAt'>;
}

interface DeleteResponseProps extends BaseResponseProps {
	payload: {
		isSuccess: boolean;
	};
}

const Pharmacy = () => {
	const history = useHistory();

	const [isLoadingUpdateStatus, setIsLoadingUpdateStatus] =
		React.useState<boolean>(false);
	const [isLoadingDelete, setIsLoadingDelete] = React.useState<boolean>(false);

	const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
	const [isModalDeleteVisible, setIsModalDeleteVisible] =
		React.useState<boolean>(false);

	const [tmpData, setTmpData] = React.useState<PartnerProps>(initialPharmacy);

	const [rxData, setRxData] = useState([]);
	const [medicineData, setMedicineData] = useState([]);

	const [searchDoctor, setSearchDoctor] = useState<any>('');
	const [searchDoctorValue] = useDebounce(searchDoctor, 500);

	const [searchMedicine, setSearchMedicine] = useState<any>('');
	const [searchMedicineValue] = useDebounce(searchMedicine, 500);
	const [isRxLoading, setIsRxLoading] = useState<boolean>(true);
	const [isMedicineLoading, setIsMedicineLoading] = useState<boolean>(true);
	const [isMostUse, setIsMostUse] = useState<boolean>(false);

	const {
		isLoading,
		data,
		pagination,
		setData,
		setSearch,
		setQuery,
		changePage,
		query,
	} = useFetchList<PartnerProps>({
		endpoint: 'partners',
		limit: 10,
		initialQuery: {
			includePartnerTypes: 'PHARMACY',
		},
	});

	const fetchRx = async () => {
		try {
			setIsRxLoading(true);

			const resPoint = await httpRequest.get<any>(
				`/rx-prescriptions?search=${searchDoctorValue}&isTemporary=false`,
			);
			if (resPoint && resPoint.data.payload) {
				setRxData(resPoint.data.payload.results);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsRxLoading(false);
		}
	};

	const fetchMedicine = async () => {
		try {
			setIsMedicineLoading(true);

			const resPoint = await httpRequest.get<any>(
				`/drug-medicines?search=${searchMedicineValue}`,
			);
			if (resPoint && resPoint.data.payload) {
				setMedicineData(resPoint.data.payload.results);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsMedicineLoading(false);
		}
	};

	useEffect(() => {
		if (searchDoctorValue !== '' && searchDoctorValue.length >= 3) {
			fetchRx();
		}
	}, [searchDoctorValue]);

	useEffect(() => {
		if (searchMedicineValue !== '' && searchMedicineValue.length >= 3) {
			fetchMedicine();
		}
	}, [searchMedicineValue]);

	const handleFilterByDate = (date: any) => {
		if (date) {
			setQuery((oldVal) => ({ ...oldVal, filterDate: date }));
		} else {
			setQuery((oldVal) => ({ ...oldVal, filterDate: undefined }));
		}
	};

	const handleStatusChange = async () => {
		try {
			setIsLoadingUpdateStatus(true);

			let newData = [];

			const res = await httpRequest.patch<ResponseProps>(
				'/partners/' + tmpData.partnerId,
				{
					partnerId: tmpData.partnerId,
					isPublished: !tmpData.isPublished,
				},
			);
			newData = data.map((item) => {
				if (item.partnerId === res.data.payload.partnerId) {
					return {
						...item,
						isPublished: res.data.payload.isPublished,
						statusLoading: false,
					};
				}
				return item;
			});

			// const resMedicine = await httpRequest.get<any>('/drug-medicines', {
			// 	params: { partnerId: res.data.payload.partnerId },
			// });

			// if (resMedicine) {
			// 	resMedicine.data.payload.results.map((item: any) =>
			// 		httpRequest.patch<any>('/drug-medicines/' + item.drugMedicineId, {
			// 			isPublished: !tmpData.isPublished,
			// 		}),
			// 	);
			// }

			setData(newData);
			message.success('Success change ' + tmpData.partnerName + ' status.');
		} catch (error: any) {
			message.error(error.data.message);
		} finally {
			setIsModalVisible(false);
			setTmpData(initialPharmacy);
			setIsLoadingUpdateStatus(false);
		}
	};

	const handleCreatePharmacy = () => {
		history.push(`/pharmacy/add`);
	};

	const { Option } = Select;

	const handleChangeStatus = (status: string) => {
		setIsMostUse(false);
		if (status == 'all') {
			setQuery((oldVal) => ({ ...oldVal, isPublished: undefined }));
		} else if (status == 'active') {
			setQuery((oldVal) => ({ ...oldVal, isPublished: 'active' }));
		} else if (status == 'inactive') {
			setQuery((oldVal) => ({ ...oldVal, isPublished: 'inactive' }));
		} else {
			setIsMostUse(true);
			setQuery((oldVal) => ({ ...oldVal, isPublished: undefined }));
		}
	};

	const handleMostUse = (data: any) => {
		let newSortData = [...data].sort(
			(a, b) =>
				b.medicine?.totalPrescribeCurrentMedicine -
				a.medicine?.totalPrescribeCurrentMedicine,
		);

		setData(newSortData);
	};

	useEffect(() => {
		if (isMostUse && !(query as any)?.isPublished) {
			setIsMostUse(false);
			handleMostUse(data);
		}
	}, [data]);

	const handleClickDetail = (e: PharmacyProps) => {
		history.push(`/pharmacy/${e.partnerId}/details`);
	};

	const handleClickAction = (props: PartnerProps, key: string) => {
		if (key === 'edit') {
			history.push(`/pharmacy/${props.partnerId}/edit`);
		} else if (key === 'delete') {
			setTmpData(props);
			setIsModalDeleteVisible(true);
		} else if (key === 'view') {
			history.push(`/pharmacy/${props.partnerId}/details`);
		}
	};

	const handleDelete = async () => {
		try {
			setIsLoadingDelete(true);
			let newData = [];

			await httpRequest.delete<DeleteResponseProps>(
				'/partners/' + tmpData.partnerId,
			);
			newData = data.filter((item) => item.partnerId !== tmpData.partnerId);
			setData(newData);

			message.success('Successfully deleted ' + tmpData.partnerName + '.');
		} catch (error: any) {
			message.error(error.data.message);
		} finally {
			setIsModalDeleteVisible(false);
			setTmpData(initialPharmacy);
			setIsLoadingDelete(false);
		}
	};

	const handleAllSearch = (e: any) => {
		setSearchDoctor(e);
		setSearch(e);
		setSearchMedicine(e);
	};

	const columns = [
		{
			title: 'NAME',
			dataIndex: 'pharmacyName',
			key: 'pharmacyName',
			render: (text: string, record: PharmacyProps) => {
				return (
					<div
						className="table-link"
						onClick={() => handleClickDetail(record)}
						style={{
							wordBreak: 'break-word',
							whiteSpace: 'normal',
						}}
					>
						{record.partnerName}
					</div>
				);
			},
		},
		{
			title: 'TOTAL MEDICINE',
			dataIndex: 'totalMedicine',
			key: 'totalMedicine',
			render: (text: string, record: PharmacyProps) => {
				return <Text>{record.medicine?.totalMedicine}</Text>;
			},
		},
		{
			title: 'TOTAL PRESCRIBED',
			dataIndex: 'totalPrescribed',
			key: 'totalPrescribed',
			render: (text: string, record: PharmacyProps) => {
				return <Text>{record.medicine?.totalPrescribeCurrentMedicine}</Text>;
			},
		},
		{
			title: 'TOTAL POINTS',
			dataIndex: 'totalPoints',
			key: 'totalPoints',
			render: (text: string, record: PharmacyProps) => {
				return <Text>{record.medicine?.totalPointXQuantity}</Text>;
			},
		},
		{
			title: 'PUBLISHED',
			key: 'isPublished',
			dataIndex: 'isPublished',
			render: (status: any, record: PartnerProps) => (
				<>
					{
						<Switch
							loading={record.statusLoading}
							checked={record.isPublished}
							onChange={() => {
								setIsModalVisible(true);
								setTmpData(record);
							}}
						/>
					}
				</>
			),
		},
		{
			title: '',
			key: 'action',
			render: (_: any, record: PartnerProps) => (
				<Dropdown overlay={() => menu(record)} placement="bottomRight">
					<MoreOutlined style={{ cursor: 'pointer' }} />
				</Dropdown>
			),
		},
	];

	const columnDoctor = [
		{
			title: 'PRESCRIBED BY',
			dataIndex: 'doctor',
			key: 'doctorId',
			render: (text: string, record: any) => {
				return <p>{record?.doctor?.nameOfSignature}</p>;
			},
		},
		{
			title: 'PATIENT',
			dataIndex: 'doctor',
			key: 'doctorId',
			render: (text: string, record: any) => {
				return (
					<p>
						{record?.metaPatient?.name ||
							record?.metaPatient?.firstName +
								' ' +
								record?.metaPatient?.middleName ||
							'' + ' ' + record?.metaPatient?.lastName}
					</p>
				);
			},
		},
		{
			title: 'PATIENT PHONE',
			dataIndex: 'doctor',
			key: 'doctorId',
			render: (text: string, record: any) => {
				return (
					<p style={{ textAlign: 'center' }}>
						{record?.metaPatient?.phone || '-'}
					</p>
				);
			},
		},
		{
			title: 'PHARMACY',
			render: (text: string, record: any) => {
				return record.prescriptionItems.map((item: any) => {
					return (
						<p style={{ textAlign: 'center' }}>
							{item.drugMedicineDetail?.partner?.partnerName || '-'}
						</p>
					);
				});
			},
		},
		{
			title: 'PRESCRIBED DATE',
			render: (text: string, record: any) => {
				return (
					<p>
						{moment(record?.createdAt).format('MMMM DD, YYYY') +
							' ' +
							moment(record?.createdAt).format('HH:mm')}
					</p>
				);
			},
		},
		{
			title: 'MEDICINE',
			render: (text: string, record: any) => {
				return record.prescriptionItems.map((item: any) => {
					return <p>{item?.drugMedicineDetail?.drugMedicineName}</p>;
				});
			},
		},
		{
			title: 'QUANTITY',
			render: (text: string, record: any) => {
				return record.prescriptionItems.map((item: any) => {
					return (
						<ul>
							<li>{item?.quantity}</li>
						</ul>
					);
				});
			},
		},
		{
			title: 'POINTS',
			render: (text: string, record: any) => {
				return record.prescriptionItems.map((item: any) => {
					return (
						<ul>
							<li>{item?.drugMedicineDetail?.points || '-'}</li>
						</ul>
					);
				});
			},
		},
	];

	const columnsMedicine = [
		{
			title: 'MEDICINE NAME',
			render: (text: string, record: any) => {
				return <p>{record.drugMedicineName}</p>;
			},
		},
		{
			title: 'TOTAL QUANTITY',
			render: (text: string, record: any) => {
				return <p>{record.medicine?.totalPrescribedXQuantity}</p>;
			},
		},
		{
			title: 'TOTAL PRESCRIBED',
			render: (text: string, record: any) => {
				return <p>{record.medicine?.totalPrescribed}</p>;
			},
		},
		{
			title: 'POINTS PER PRESCRIBED',
			render: (text: string, record: any) => {
				return <p>{record?.points}</p>;
			},
		},
		{
			title: 'TOTAL POINTS',
			render: (text: string, record: any) => {
				return <p>{record.medicine?.totalPoints}</p>;
			},
		},
		{
			title: 'PHARMACY',
			render: (text: string, record: any) => {
				return <p>{record?.partner?.partnerName}</p>;
			},
		},
	];

	const menu = (record: PartnerProps) => (
		<Menu onClick={(e) => handleClickAction(record, e.key)}>
			<Menu.Item key="view">View Pharmacy</Menu.Item>
			<Menu.Item key="edit">Edit Pharmacy</Menu.Item>
			<Menu.Item key="delete">Delete Pharmacy</Menu.Item>
		</Menu>
	);

	return (
		<>
			<Row justify="space-between">
				<Col flex={50}>
					<HeaderSection
						icon={<HardDriveGreyIcon />}
						title="Pharmacy"
						subtitle="Manage your pharmacy"
					/>
				</Col>
				<Col flex={1}>
					<Space>
						<AppButton type="primary" onClick={handleCreatePharmacy}>
							Add Pharmacy
						</AppButton>
					</Space>
					<Space
						style={{
							paddingLeft: '2%',
						}}
					></Space>
				</Col>
			</Row>
			<ContainerFilter style={{ marginTop: '10px' }}>
				<Input
					size="large"
					placeholder="Search Pharmacy Name, Doctor Name, Patient Name, Medicine Name"
					prefix={<SearchOutlined />}
					allowClear
					value={searchDoctor}
					onChange={(e) => handleAllSearch(e.target.value)}
				/>

				<CustoRangePicker
					size="large"
					allowEmpty={[true, true]}
					onCalendarChange={handleFilterByDate}
					showTime={{
						hideDisabledOptions: true,
						defaultValue: [
							moment('00:00:00', 'HH:mm:ss'),
							moment('23:59:59', 'HH:mm:ss'),
						],
					}}
					showHour={false}
					showMinute={false}
					showSecond={false}
				/>
				<Select
					size="large"
					allowClear
					style={{ width: 160 }}
					onChange={handleChangeStatus}
					placeholder="Filter"
				>
					<Option value="all">All</Option>
					<Option value="active">Published</Option>
					<Option value="inactive">Unpublished</Option>
					<Option value="most-use">Most use</Option>
				</Select>
			</ContainerFilter>

			<Spin spinning={isLoading} style={{ flex: 1, display: 'flex' }}>
				{data?.length > 0 ? (
					<div>
						<Table
							// loading={isLoading}
							columns={columns}
							dataSource={data}
							pagination={false}
						/>

						<>
							<CustomPagination
								current={pagination.page}
								total={pagination.totalData}
								defaultPageSize={pagination.perPage}
								pageSizeOptions={['10', '25', '50', '100']}
								showSizeChanger={true}
								showTotal={(total, range) =>
									`${range[0]} - ${range[1]} of ${total}`
								}
								onChange={changePage}
								locale={{ items_per_page: '' }}
								responsive={true}
							/>
							<PaginationText>
								<h4>Per Page</h4>
							</PaginationText>
						</>
					</div>
				) : rxData?.length > 0 && searchDoctor.length >= 3 ? (
					<div>
						<ContainerFilter></ContainerFilter>
						<Table
							loading={isRxLoading}
							columns={columnDoctor}
							dataSource={rxData}
							pagination={false}
						/>
					</div>
				) : medicineData?.length > 0 && searchMedicine.length >= 3 ? (
					<Table
						loading={isMedicineLoading}
						columns={columnsMedicine}
						dataSource={medicineData}
						pagination={false}
					/>
				) : (
					<Table
						// loading={isLoading}
						columns={columns}
						dataSource={data}
						pagination={false}
					/>
				)}
			</Spin>

			<Modal
				title="Update status confirmation"
				visible={isModalVisible}
				onOk={handleStatusChange}
				onCancel={() => {
					setIsModalVisible(false);
					setTmpData(initialPharmacy);
				}}
				okText="Yes"
				confirmLoading={isLoadingUpdateStatus}
				okButtonProps={{ type: 'primary' }}
			>
				<p>
					Are you sure want to change <b>"{tmpData.partnerName}"</b> status to{' '}
					<b>"{!tmpData.isPublished ? 'Active' : 'Inactive'}"</b>?.{' '}
					{!tmpData.isPublished}
				</p>
			</Modal>

			<Modal
				title="Delete medicine confirmation"
				visible={isModalDeleteVisible}
				onOk={handleDelete}
				onCancel={() => {
					setIsModalDeleteVisible(false);
					setTmpData(initialPharmacy);
				}}
				okText="Yes"
				confirmLoading={isLoadingDelete}
				okButtonProps={{ type: 'primary' }}
			>
				<p>
					Are you sure want to delete <b>{tmpData.partnerName}</b>?
				</p>
			</Modal>
		</>
	);
};

export const ContainerFilter = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	margin-bottom: 15px;
`;

export default Pharmacy;

const CustomPagination = styled(Pagination)`
	background-color: white;
	margin-top: 0;
	padding: 10px;

	.ant-pagination {
		display: flex;
	}

	.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: #d81f64;
		border-right-width: 1px;
	}

	.ant-pagination-options {
		order: 1;
		margin-left: 5rem;
	}

	li.ant-pagination-total-text {
		color: #93a1b0;
		order: 2;
	}

	.ant-pagination-prev,
	.ant-pagination-item,
	.ant-pagination-jump-next,
	.ant-pagination-jump-prev,
	ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon {
		order: 3;
	}

	.ant-pagination-next {
		order: 4;
	}

	.ant-pagination-item:hover {
		border-color: #d81f64;
	}

	.ant-pagination-item-active a {
		color: #d81f64;
		border-color: #d81f64;
	}

	.ant-pagination-item-active {
		border-color: #d81f64;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: 1px solid transparent;
	}
`;

const PaginationText = styled.div`
	h4 {
		display: inline;
		position: relative;
		bottom: 38px;
		left: 10px;
		color: #93a1b0;
	}

	@media only screen and (max-width: 550px) {
		h4 {
			visibility: hidden;
		}
	}
`;

const CustoRangePicker = styled(DatePicker.RangePicker)`
	.ant-picker .ant-picker-input span.ant-picker-suffix,
	.ant-picker.ant-picker-range span.ant-picker-suffix {
		margin-left: 1px;
		margin-right: 10px;
		order: -1;
	}
	.ant-picker.ant-picker-range {
		display: flex;
		flex-direction: row-reverse;
	}
`;
