import { AxiosInstance } from 'axios';
import React from 'react';
import { useDebounce } from 'use-debounce';
import { httpRequest } from '../helpers/api';
import { generateQueryString } from '../helpers/generateQueryString';
import {
	IHttpResponse,
	INITIAL_PAGINATION,
	INITIAL_QUERY,
	IPagination,
	IPayloadPagination,
	IQuery,
} from '../helpers/pagination';
import { useHistory, useLocation } from 'react-router-dom';

type Props = {
	endpoint: string;
	initialQuery?: Object;
	limit?: number;
	pushData?: boolean;
	apiRequest?: AxiosInstance;
};

const DEFAULT_LIMIT = 25;

export default function useFetchList<DataType>(props: Props) {
	const history = useHistory();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	let queryObject: Record<string, string> = {};
	queryParams.forEach((value, key) => {
		queryObject[key] = value;
	});
	const apiRequest: AxiosInstance = props.apiRequest || httpRequest;
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [data, setData] = React.useState<Array<DataType>>([]);
	const [pagination, setPagination] = React.useState<IPagination>({
		...INITIAL_PAGINATION,
		page: parseInt(queryObject.page) || 1,
	});
	const [query, setQuery] = React.useState<IQuery>({
		...INITIAL_QUERY,
		...queryObject,
		limit: props.limit || DEFAULT_LIMIT,
		...props.initialQuery,
	});

	const [search, setSearch] = React.useState<string>();
	const [lastOffset, setLastOffset] = React.useState<number>(query.offset || 0);
	const [searchValue] = useDebounce(search, 500);

	const fetchList = async (newQuery?: any) => {
		try {
			setIsLoading(true);

			let objectQuery = {
				...query,
			};

			if (newQuery) {
				objectQuery = {
					...objectQuery,
					...newQuery,
				};
			}

			if (objectQuery?.search) {
				objectQuery.search = encodeURIComponent(
					(objectQuery.search as string).trim(),
				).replace(/(_|%|\\)/g, '\\$1');
			}

			const res = await apiRequest.get<
				IHttpResponse<IPayloadPagination<DataType>>
			>(`${props.endpoint}${generateQueryString(objectQuery)}`);

			if (res?.data?.payload) {
				setPagination((oldVal) => {
					return {
						...oldVal,
						perPage: props.limit || DEFAULT_LIMIT,
						prev: res.data.payload.prev,
						next: res.data.payload.next,
						totalData: res.data.payload.count,
						countPage: Math.ceil(
							res.data.payload.count / (props.limit || DEFAULT_LIMIT),
						),
					};
				});

				if (props.pushData) {
					setData((value) => [...value, ...res.data.payload.results]);
				} else {
					setData(res.data.payload.results);
				}
			}
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	const getPage = (offset: number, size: number) => {
		return Math.floor(offset / size) + 1;
	};

	React.useEffect(() => {
		if (!!query.offset && query.offset !== lastOffset) {
			setLastOffset(query.offset);
		}
		(Object.entries(query) as [string, string | number][]).forEach(
			([key, value]) => {
				if (value === undefined) {
					queryParams.delete(key);
				} else if (key !== 'search') {
					queryParams.set(key, value?.toString());
				}
			},
		);

		const currentPage = getPage(
			query.offset || 0,
			query.limit || DEFAULT_LIMIT,
		);
		queryParams.set('page', currentPage.toString());

		const currentLoc = location.pathname;
		history.replace(`${currentLoc}?${queryParams.toString()}`);
		fetchList();
	}, [query]);

	React.useEffect(() => {
		const page = parseInt(queryObject.page);
		const currentPage = getPage(query.offset || 0, query.limit || 1);
		if (currentPage > 0 || page > 0) {
			changePage(currentPage || page, query.limit);
		}
	}, []);

	React.useEffect(() => {
		setQuery((e: IQuery) => {
			return {
				...e,
				search: searchValue as string,
				offset: !!searchValue ? 0 : lastOffset,
			};
		});
	}, [searchValue]);

	const changePage = (page: any, size?: number) => {
		setPagination((oldVal) => {
			return {
				...oldVal,
				page,
				size,
			};
		});
		setQuery((oldVal) => {
			return {
				...oldVal,
				offset: (page - 1) * (size || DEFAULT_LIMIT),
				limit: size,
			};
		});
	};

	const changeLimit = (current: number, perPage: number) => {
		setPagination((oldVal) => {
			return {
				...oldVal,
				perPage,
			};
		});

		setQuery((oldVal) => {
			return {
				...oldVal,
				limit: perPage,
				offset: 0,
			};
		});
	};

	return {
		DEFAULT_LIMIT,
		isLoading,
		data,
		pagination,
		query,
		setData,
		setPagination,
		setQuery,
		setSearch,
		changePage,
		fetchList,
		setIsLoading,
		changeLimit,
	};
}
