export function capitalizeFirstLetter(text: string) {
	return text.charAt(0).toUpperCase() + text.slice(1);
}

export function showReadableText(str: string) {
	return str.replace(/_/g, ' ');
}

export function toTitleCase(str: string) {
    return str
        .toLowerCase()
        .replace(/[_-]/g, ' ') 
        .replace(/\b\w/g, (char) => char.toUpperCase());
}