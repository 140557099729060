import { Image, Space, Divider, Button } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppButton from '../../components/AppButton';
import AppCard from '../../components/AppCard';
import DetailItem from '../../components/DetailItem';
import HeaderSection from '../../components/HeaderSection';
import styled from 'styled-components';
import HTMLPreview from '../../components/HTMLPreview';
import SectionContent from '../../components/SectionContent';
import { httpRequest } from '../../helpers/api';
import { formatDate } from '../../helpers/constant';
import useDetailBreadcrumbs from '../../hooks/useDetailBreadcrumbs';
import { BaseResponseProps } from '../../types/config.type';
import {
	EbookProps,
	initialEbooks,
	CustomOutlineItem,
	CalculatorItem,
	CalculatorType,
} from '../../types/ebook.type';
import { GenreProps } from '../../types/genre.type';
import { Document, Page, pdfjs } from 'react-pdf';
import { EbookVideoProps } from '../../types/video.type';
import { replaceUnderscoreWithSpace } from '@qlibs/utils';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js`;

interface IParams {
	ebookId: string;
	partnerId: string;
}

interface ResponseProps extends BaseResponseProps {
	payload: Omit<EbookProps, 'createdAt' | 'updatedAt'> & {
		customOutline: string;
		metaEbook: CustomOutlineItem;
		calculator: CalculatorItem;
	};
}

const EbookDetail: React.FC = () => {
	const { setBreadcrumbDetails } = useDetailBreadcrumbs();
	const history = useHistory();
	const { ebookId, partnerId } = useParams<IParams>();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [selectedImage, setSelectedImage] = React.useState<UploadFile<any>>({
		url: '',
		uid: '',
		name: '',
	});
	const [images, setImages] = React.useState<
		UploadChangeParam<UploadFile<any>>['fileList']
	>([]);
	const [ebook, setEbook] = React.useState<EbookProps>(initialEbooks);
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [numPages, setNumPages] = React.useState<number>(1);

	React.useEffect(() => {
		const fetchEventDetail = async () => {
			try {
				setIsLoading(true);

				const res = await httpRequest.get<ResponseProps>('/ebooks/' + ebookId);
				const customOutline: CustomOutlineItem[] = JSON.parse(
					res.data.payload.customOutline,
				);

				const calculator: CalculatorItem[] = res.data.payload.calculator;

				const metaEbook = JSON.parse(res.data.payload.metaEbook);

				const fetchedEbook: EbookProps = {
					...res.data.payload,
					createdAt: new Date(),
					updatedAt: new Date(),
					customOutline,
					calculator,
					metaEbook,
				};

				setEbook(fetchedEbook);

				setIsLoading(false);

				const bcDetails = [
					{
						field: 'ebookId',
						value: ebookId,
						label: res.data.payload.title,
					},
				];
				setBreadcrumbDetails(bcDetails);
			} catch (error) {
				setIsLoading(false);
			}
		};

		fetchEventDetail();
	}, [ebookId]);

	const isOutlineEmpty = (outline: any[]) => {
		return outline.length === 0;
	};

	const handlePageChange = (newPageNumber: number) => {
		if (newPageNumber >= 1 && newPageNumber <= numPages) {
			setPageNumber(newPageNumber);
		}
	};

	return (
		<div>
			<HeaderSection
				icon="back"
				title="E-Book Detail"
				subtitle="Manage your ebook data"
				rightAction={
					<Space>
						<AppButton
							type="primary"
							onClick={() => {
								if (partnerId) {
									history.push(
										`/publisher/${partnerId}/detail/ebooks/${ebookId}/edit`,
									);
								} else {
									history.push('/ebooks/' + ebookId + '/edit');
								}
							}}
						>
							Edit
						</AppButton>
					</Space>
				}
			/>
			<AppCard loading={isLoading}>
				<SectionContent
					groupTitle="E-Book Information"
					helpers={[
						{
							title: 'Information',
							content:
								'Accepted image type are jpg, jpeg and png. Max file size is 1 Mb.',
						},
					]}
				>
					<Space
						style={{
							width: '100%',
							background: '#f5f5f5',
							justifyContent: 'center',
							marginBottom: '20px',
							padding: '10px',
						}}
					>
						<DetailItem type="image" label="" content={ebook.imageUrl || ''} />
					</Space>

					<DetailItem label="Title" content={ebook.title} />

					<DetailItem
						label="Description"
						content={<HTMLPreview html={ebook.description} />}
					/>

					<DetailItem
						label="Genre"
						content={sortTags(ebook.genres || [])
							.map((item) => item.genreName)
							.join(', ')}
					/>

					<DetailItem label="Publisher" content={ebook.partners?.partnerName} />
					<DetailItem label="Author" content={ebook.author} />

					<DetailItem
						label="Original Price"
						content={`₱ ${ebook.originalPrice}`}
					/>
					<DetailItem label="Final Price" content={`₱ ${ebook.finalPrice}`} />
					<DetailItem
						label="Published"
						content={formatDate(ebook.publicationDate)}
					/>

					<DetailItem label="ISBN" content={ebook.bookNumber} />
				</SectionContent>

				<Divider />
				<SectionContent
					groupTitle="PDF Preview"
					helpers={[
						{
							title: 'Information',
							content: 'Preview your PDF file below.',
						},
					]}
				>
					{ebook.fileUrl ? (
						<div>
							<CustomePdf>
								<Document
									file={ebook.fileUrl}
									onLoadSuccess={({ numPages }) => {
										setNumPages(numPages);
									}}
								>
									<Page pageNumber={pageNumber} width={500} />
								</Document>
							</CustomePdf>
							<NavigationButtons>
								<div
									style={{ cursor: 'pointer' }}
									onClick={() => handlePageChange(pageNumber - 1)}
								>
									{'<<'}
								</div>
								<div>
									Page {pageNumber} of {numPages}
								</div>
								<div
									style={{ cursor: 'pointer' }}
									onClick={() => handlePageChange(pageNumber + 1)}
								>
									{'>>'}
								</div>
							</NavigationButtons>
						</div>
					) : (
						<DetailItem label="PDF Preview" content="No PDF file available" />
					)}
				</SectionContent>

				<Divider />
				<SectionContent
					groupTitle="Table Of Content"
					helpers={[
						{
							title: 'Information',
							content:
								'You can add a title or heading at the same level or one level below the selected parent heading',
						},
					]}
				>
					{ebook.customOutline.length > 0 ? (
						ebook.customOutline.map(
							(outline: CustomOutlineItem, index: number) => (
								<CustomeItem key={index}>
									<DetailItem
										label={index === 0 ? 'Title' : ''}
										content={outline.title}
									/>
									<DetailItem
										label={index === 0 ? 'Page' : ''}
										content={outline.pageNumber}
									/>
								</CustomeItem>
							),
						)
					) : ebook.metaEbook &&
					  ebook.metaEbook.outline &&
					  !isOutlineEmpty(ebook.metaEbook.outline) ? (
						ebook.metaEbook.outline.map((item: any, index: number) => (
							<CustomeItem key={index}>
								<DetailItem
									label={index === 0 ? 'Title' : ''}
									content={item.title}
								/>
								<DetailItem
									label={index === 0 ? 'Page' : ''}
									content={item.pageNumber.toString()}
								/>
							</CustomeItem>
						))
					) : (
						<DetailItem
							label="Table of Content"
							content="No content available"
						/>
					)}
				</SectionContent>

				<Divider />
				<SectionContent
					groupTitle="Video Attachment"
					helpers={[
						{
							title: 'Information',
							content:
								'Enter the video url and link to the page to play on the related page',
						},
					]}
				>
					{ebook.ebookVideos && ebook.ebookVideos.length > 0 ? (
						ebook.ebookVideos.map((video: EbookVideoProps, index: number) => (
							<VideoItem key={video.ebookVideoId}>
								<DetailItem
									label={index === 0 ? 'Video URL' : ''}
									content={
										<StyledLink href={video.videoUrl}>
											{video.videoUrl}
										</StyledLink>
									}
								/>
								<DetailItem
									label={index === 0 ? 'Page' : ''}
									content={video.pageNumber}
								/>
							</VideoItem>
						))
					) : (
						<DetailItem
							label="Video attachments"
							content="No videos attached"
						/>
					)}
				</SectionContent>
				<Divider />
				<SectionContent
					groupTitle="Medical Calculator"
					helpers={[
						{
							title: 'Information',
							content:
								'Select a calculator and link it to the related page to display.',
						},
					]}
				>
					{ebook.calculator && ebook.calculator.length > 0 ? (
						ebook.calculator.map(
							(calculators: CalculatorItem, index: number) => (
								<CustomeItem key={index}>
									<DetailItem
										label={index === 0 ? 'Type' : ''}
										content={replaceUnderscoreWithSpace(calculators.type)}
									/>
									<DetailItem
										label={index === 0 ? 'Page' : ''}
										content={calculators.page}
									/>
								</CustomeItem>
							),
						)
					) : (
						<DetailItem
							label="Medical Calculator"
							content="No medical calculator selected"
						/>
					)}
				</SectionContent>
			</AppCard>
		</div>
	);
};

export default EbookDetail;

function sortTags(tags: GenreProps[]) {
	tags.sort((a, b) => a.genreName.localeCompare(b.genreName));
	return tags;
}

const VideoItem = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: -2px;
	width: 100%;

	& > div {
		flex: 1;
		text-align: left;
		padding-right: 10px;
	}

	& > div:last-child {
		text-align: right;
		padding-left: 10px;
	}
`;

const StyledLink = styled.a`
	color: #1890ff;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
`;

const CustomeItem = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: -2px;
	width: 100%;

	& > div {
		flex: 1;
		text-align: left;
		padding-right: 10px;
	}

	& > div:last-child {
		text-align: right;
		padding-left: 10px;
	}
`;

const CustomePdf = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f0f0f0;
	padding: 6px;
	border: 1px solid #ccc;
	border-radius: 5px;
	min-height: 400px;
	width: 100%;
	box-sizing: border-box;
`;

const NavigationButtons = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 10px;
	gap: 10px;

	& > button {
		font-size: 14px;
	}
`;
